.room-card .room-option{
    cursor: pointer;
    transition: ease all 0.2s;
}

.room-card .room-option:hover{
    background-color: var(--material-blue);
}

.room-card .room-option.selected{
    border-color: #222 !important;
    background-color: var(--material-blue);
}