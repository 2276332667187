@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }

html{
  font-size: 16px;
}

@media (max-width: 576px){
  html{
    font-size: 14px;
  }
}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px !important;
  }
}

:root{
  --material-blue: #ceebf2;
  --material-beage: #FEE4D4;
  --light-color: #f2f2f2;
  --material-violet:#E7DEF8;
  --material-grey : #D9D4E7;
  --material-yellow:#F2F6E4;
  --material-cream:#EFD9CA;
  --material-green:#CBE5DE;
  --material-red:#F5C6CF;
  --material-bg:#F9F8FC;
  --material-silver:#EFF0F3;
  --material-orange:#FFDC9A;
  --material-pink: #F1DDEC;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 10px;
}

body {
  /* font-family: 'Poppins', sans-serif !important; */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  color:#222222;
  font-family: 'proxima-nova' !important;

}

.cap{
  text-transform: uppercase !important;
}

.cards {
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 2rem;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.ms-auto {
  margin-left: 0px;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1100px;
  }
}

@media (min-width: 576px){
  .modal-dialog {
      max-width: 600px;
      margin: 1.75rem auto;
  }
}

.destination-card {
  width: 300px;
  min-width: 300px;
  height: 200px;
  border-radius: 10px;
  cursor: pointer;
  transition: ease-out all 0.3s;
}

.destination-card:hover {
  width: 320px;
  min-width: 320px;
}

.white-cards {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  height: 100%;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
}

.white-cards:hover {
  background: var(--material-silver);
  border-color: var(--material-silver);
}

.border-box {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
}

/* Flights Page */

.flight-card {
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
}

.flight-card:hover{
  background-color: var(--material-beage);
  transform: scale(97%);
}

.fares{
  padding: 0.4rem;
  background: #fff;
  cursor: pointer;
  border: 1px solid transparent;
  height: 6rem;
  transition: ease-in-out all 0.2s;
}

.fares:hover{
  background-color: var(--material-beage);
}

.fares-hidden{
  border: 1px solid transparent;
  background: #fff;
  height: 0px;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  overflow: hidden !important;
  transition: ease-in-out all 0.2s;
}

.fares-selected{
  border-radius: 10px;
  cursor: pointer;
  height: 6rem;
  padding: 0.75rem;
  border: 1px solid #222 !important;
  background-color: var(--material-blue);
  transition: ease-in-out all 0.2s;
}

.date-card {
  font-size: 0.8rem;
  min-width: 120px;
  max-width: 150px;
  margin-right: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out all 0.15s;
}

.date-card:hover{
  transform: scale(95%);
}

input[type="range"]::-webkit-slider-thumb {
  background: #000;
  margin-top: -7px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  background: #000;
  border-radius: 50px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F9FA;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.08em solid currentColor;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-top: 5px;
  margin-right: 15px;
}

infut[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(
    14% 44%,
    0 65%,
    50% 100%,
    100% 16%,
    80% 0%,
    43% 62%
  );
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.form-check-input:checked {
  background-color: #000;
  border-color: #000;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.18rem;
  cursor: pointer;
}

.cursor-pointer{
  cursor: pointer;
}

.rounded-4{
  border-radius: 0.5rem;
}

.text-small{
  font-size: 0.8rem;
}

.text-tiny{
  font-size: 0.7rem;
}

.btn:hover{
  transition: ease-out all 0.2s;
}

.btn:hover{
  transform: scale(104%);
}
.display-7{
  font-weight: 2rem;
}

.search-input{
  border-radius: 4px;
  border: 1px solid #222;
}

.search .search-input{
  border-radius: 4px;
  border: 1px solid var(--material-grey);
}

.modal-header{
  border-radius: 0;
}

.shadow-md {
  box-shadow: 0 0 1rem rgba(0,0,0,.175)!important;
}

.tabs{
  background: #fff;
  display: flex;
  align-items: center;
  position: sticky;
  top:0;
  z-index:15;
  overflow: scroll;
  cursor: pointer;
}

.tabs .tab{
  flex: 1;
  min-width: 100px;
  border-top: 5px solid #fff;
}

.tabs .tab-active{
  min-width: 100px;
  flex: 1;
  border-bottom: 5px solid #000;
}
.tabs .tab-active>button{
  font-weight: 600;
}

.card{
  border: 1px solid var(--material-grey);
  border-radius: 4px;
}

.deal-card {
  border-radius: 1rem;
  background: #fff;
  min-width: 260px;
  cursor: pointer;
  transition: ease-in-out all 0.15s;
}

.text-truncate.text-truncate--2{
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.overflow-y-hidden{
  overflow-y: hidden !important;
}

.overflow-x-hidden{
  overflow-x: hidden !important;
}

.scroll-smooth{
  scroll-behavior: smooth;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.select-chips{
  border: 1px solid #222;
  border-radius: 0.4rem;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0.25rem;
  white-space: nowrap;
}

.select-chips:hover{
  background-color: var(--material-beage);
}

.select-chips.selected{
  border: 1px solid #222;
  border-radius: 0.4rem;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0.25rem;
  background-color: var(--material-beage)
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.Stencil{
  background:#efefef
}
.Stencil.has-shimmer{
  animation-duration:1.5s;
  animation-fill-mode:forwards;
  animation-iteration-count:infinite;
  animation-name:shimmer;
  animation-timing-function:linear;
  background:linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-size:1200px 1200px;
  position:relative
}


@keyframes shimmer{
  0%{
    background-position:-468px 0
  }
  100%{
    background-position:468px 0
  }
}
div.shimmer{
  height: 10px;
  margin-top: 12px;
  width: 200px;
}

.sm-absolute{
  position: absolute;
}

.sm-100{
  width: 100%;
}

@media (min-width: 576px) {
  .sm-absolute{
    position: relative;
  }
  .sm-100{
    width: auto;
  }
}

.back-btn{
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  margin-left: -3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-btn:hover{
  background-color: #fff;
}

.check-child:not(:has(section)){
  display: none !important;
}

.scrollbar-autohide::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.scrollbar-autohide:hover::-webkit-scrollbar-thumb {
  background-color: #999 !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 50px;
}

.flex-center{
  align-items: center;
  justify-content: center;
}

.success-pill{
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    background-color: #CEE5D0;
    color: #198754;
}

.danger-pill{
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  background-color: #FFF2F2;
  color: #dc3545;
}

/* Buses */

.bus-card {
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
  transition: ease-in-out all 0.2s;
}

.bus-card:hover{
  background-color: var(--material-beage);
  transform: scale(97%);
}

.bus-card .info{
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 0.25rem;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.7rem;
  transition: ease all 0.2s;
}

.bus-card .info:hover{
  color: #fff;
  background-color: var(--bs-primary);
  border: 1px solid transparent;
}

.boarding-point{
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: ease all 0.2s;
  font-size: 0.8rem;
  white-space: nowrap;
}

.boarding-point:hover{
  background-color: var(--material-beage);
  border-color: var(--material-beage);
  transform: scale(98%);
}

.boarding-point.active{
  background-color: var(--material-blue);
  border: 1px solid #000;
}

.guests-list:not(:has(div))::before {
  content: "Add a new guest for this Room";
  font-size: 0.8rem;
  color: #666;
}

/* Support Page */

.support .ticket{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-size: 0.8rem;
  border-bottom: 1px solid var(--theme-border-color);
  background-color: #fff;
  cursor: pointer;
  transition: ease all 0.2s;
  border-radius: 0.4rem;
}

.support .ticket:hover{
  background-color: #f2f2f2;
}

.support .ticket-active{
  border-color: var(--theme-primary-color);
  background-color: var(--theme-primary-color);
  columns: #fff !important;
}

.support .details{
  flex-grow: 1;
}

.text-pill{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-variant-color);
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  color: var(--theme-text-color);
  font-size: 0.6rem;
  background-color: var(--theme-variant-color);
}

.pending-pill{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-border-color);
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  color: var(--theme-on-variant-color);
  font-size: 0.6rem;
  background-color: var(--theme-variant-color);
}

.success-pill{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-primary-container);
  color: var(--theme-primary-color);
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  font-size: 0.6rem;
  background-color: var(--theme-primary-container);
}

.danger-pill{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-color: 1px solid var(--theme-error-container);
  color: var(--theme-error-color);
  border-radius: 50px;
  padding: 0.2rem 0.6rem;
  font-size: 0.6rem;
  background-color: var(--theme-error-container);
}

.payment-ongoing{
  display: block;
  position: relative;
  height: 12px;
  width: 80%;
  border: 1px solid #111;
  border-radius: 10px;
  overflow: hidden;
}

.payment-ongoing::after {
  content: '';
  width: 40%;
  height: 100%;
  background: #f1c102;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.rounded-5{
  border-radius: 1rem;
}