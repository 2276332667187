.Voucher-Preview:hover::before{
    content: url(./expand.svg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 0.5rem
}

.Voucher-Preview{
    min-width: 3.3rem;
    max-width: 3.3rem;
    height: 3.3rem;
    margin: 0.25rem;
    cursor: pointer;
    border-radius: 0.75rem;
    position: relative;
}
