.hotel-search-input{
    width: 100%;
    transition: ease-in-out all 0.1s;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
}

.hotel-search-input::placeholder{
    font-size: 0.9rem !important;
}


.hotel-search{
    position: absolute;
    top: calc(100% - 7px);
    left: 0px;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0.5rem;
    width: calc(100%);
    border: 1px solid #ddd;
    border-top: none;
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,0.1);
}

.hotel-search .item{
    padding: 0.75rem;
    cursor:pointer;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: ease-in-out all 0.1s;
}

.hotel-search .header{
    padding: 0.5rem;
    padding-top: 0;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.hotel-search .item:hover{
    background-color: var(--material-beage);
}

.text-input-label{
    font-size: 0.8rem;
    color: #ddd;
}
