.sidebar-wrapper{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height: 100vh;
    z-index: 20;
    background: rgba(0,0,0,0.2);
}
.sidebar-wrapper .sidebar{
    width:70%;
    max-width: 250px;
    height: 100vh;
    z-index: 20;
    background: #fff;
}

.sidebar-wrapper .sidebar .option{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: ease all 0.15s;
}

.sidebar-wrapper .sidebar .option-active{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: ease all 0.15s;
    background-color: #d0d0ed;
}

.sidebar-wrapper .sidebar .option:hover{
    background-color: #d0d0ed;
}