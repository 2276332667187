.loginWithDice{
    border: none;
    width: 100%;
    padding: 10px;
    color: #ffff;
    background: #0d6efd;
    border-radius: 7px;
    cursor: pointer;
    transition: ease-in-out all 0.2s;;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.loginWithDice:hover{
    background: #222;
}