  .srp-progress {
    width: 100%;
    height: 30px;
    position: relative;
    overflow: hidden;
  }

  .srp-progress .srp-fill-bar {
    position: absolute;
    height: 30px;
    background: linear-gradient(to right, #00E8F3 , #03fc58);
    transition: width 1s ease-in-out;
    border-radius: 0px 50px 50px 0px;
  }

  .hide-bar-anim{
    animation: hide-bar 0.2s ease-in-out 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  .fill-bar-anim{
    animation: fill-bar 6s ease-in-out 1;
    animation-fill-mode: forwards;
  }

  .full-bar-anim{
    animation: full-bar 1s ease-in-out 1;
    animation-fill-mode: forwards;
  }

  .move-plane-anim{
    animation: move-plane 6s ease-in-out 1;
    animation-fill-mode: forwards;
  }

  .end-plane-anim{
    animation: end-plane 1s ease-in-out 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes fill-bar {
    0% {
      width: 0px;
    }
    100% {
      width: 80%;
    }
  }

  @keyframes full-bar {
    0% {
      width: 80%;
    }
    100% {
      width: 100%;
    }
  }
  
  
  @keyframes move-plane {
    0% {
      left: 5px;
    }
    100% {
      left: calc(80% + 5px);
    }
  }
  
  @keyframes end-plane {
    0% {
      left: calc(80% + 5px);
    }
    100% {
      left: calc(100% + 5px);
    }
  }
  
  @keyframes hide-bar {
    0% {
      height: 30px;
    }
    100% {
      height: 0px;
    }
  }