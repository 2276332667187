.update-wrapper{
    position: fixed;
    z-index: 100;
    bottom: 1rem;
    right: 1rem;
}

.update-wrapper .notification{
    border-radius: 0.5rem;
    background-color: #fff;
    padding: 1rem;
    text-align: center;
    box-shadow: 0rem 0.2rem 0.5rem rgba(0,0,0,0.2);
    margin-top: 1rem;
    max-width: 300px;
}

.update-wrapper p{
    font-size: 0.8rem;
}

.update-wrapper button{
    background-color: #222;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: ease all 0.2s;
}

.update-wrapper button:hover{
    transform: scale(104%);
}

.update-wrapper .cancel-btn{
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
}

@media (max-width: 576px){
    .update-wrapper .notification{
        max-width: 100% !important
    }
    .update-wrapper{
        bottom: 0rem;
        right: 0rem;
    }
    
  }
  