.navigation {
  background: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

.responsive-nav-web {
  display: none;
}
.responsive-nav-m {
  display: flex;
}

@media (min-width: 992px) {
  .responsive-nav-web {
    display: flex;
  }
  .responsive-nav-m {
    display: none;
  }
}

@media (min-width: 768px) {
  .navigation {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation.navbar-light .navbar-nav .nav-link.active,
.navigation.navbar-light .navbar-nav .nav-link.active:focus,
.navigation.navbar-light .navbar-nav .nav-link.active:hover {
  color: #000;
  box-shadow: none;
  background: #fff;
  pointer-events: none;
}

.navigation.navbar .navbar-nav .nav-link {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 7px;
  transition: ease-in-out all 0.2s;
}

.navigation.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navigation.navbar-light .navbar-nav .nav-link:focus,
.navigation.navbar-light .navbar-nav .nav-link:hover {
  color: #000 !important;
  background-color: #fff;
}

.navigation li > .dropdown-menu {
  box-shadow: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem;
  border: none;
  margin-top: 5px;
}

.navigation .dropdown-menu .dropdown-item:focus,
.navigation .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
  font-size: 0.9rem;
  border-radius: 0.25rem;
}

.navigation .dropdown-menu .dropdown-item:focus,
.navigation .dropdown-menu .dropdown-item:hover {
  background: #f2f2f2;
  color: inherit;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}
