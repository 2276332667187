.seat-map{
    width: 100%;
}

.seat-map .select-seats .flightseats{
    width:max-content;
    margin: auto;
    display: grid;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    background-color: #f2f2f2;
    padding: 1rem;
    border-radius: 0.4rem;
}

.seat-map .select-seats .seat{
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 0.25rem;
    cursor: pointer;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seat-map .select-seats .seat:hover{
    background-color: var(--bs-green);
    color: #fff;
    cursor: pointer;
}

.seat-map .select-seats .seat.selected{
    background-color: var(--bs-green) !important;
    color: #fff !important;
}

.seat-map .select-seats .seat.free{
    background: #a3d4a7;
}

.seat-map .select-seats .seat.free:hover{
    background: #198754;
}

.seat-map .select-seats .seat.disabled{
    background-color: var(--material-silver) !important;
    color: #000 !important;
    border: 1px solid #ddd !important;
    cursor: default !important;
}