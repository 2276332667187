.bus-seatmap .label{
    font-weight: bold;
    margin-bottom: 1rem;
}

.bus-seatmap .legend{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}

.bus-seatmap .indicator{
    width: 20px;
    height: 20px;
    border-radius: 25px;
    border: 1px solid #222;
}

.bus-seatmap .wrapper-busseats{
    background-color: var(--material-silver);
    padding: 1rem;
    border-radius: 0.5rem;
}

.bus-seatmap .select-busseats{
    display: grid;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
}

.bus-seatmap .select-busseats .seat{
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.25rem;
    cursor: pointer;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bus-seatmap .select-busseats .seat.selected{
    background-color: var(--bs-green) !important;
    color: #fff !important;
    border: 1px solid var(--bs-green) !important;
}

.bus-seatmap .select-busseats .seat.disabled{
    background-color: var(--material-silver) !important;
    color: #000 !important;
    border: 1px solid #ddd !important;
    cursor: default !important;
}

.bus-seatmap .select-busseats .seat.female{
    background-color: #FFE6F7 !important;
    color: #000 !important;
    border: 1px solid #ddd !important;
    cursor: default !important;
}

.bus-seatmap .select-busseats .seat:hover{
    border: 1px solid transparent;
    background-color: var(--bs-green);
    color: #fff;
}
