input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.morph-label{
    padding: 0rem 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 0.75rem;
    color: #6c757d;
    transition: ease-out all 0.15s;
    pointer-events: none;
}

.morph-active{
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    background-color: #fff !important;
}

input:focus + .morph-label{
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    background-color: #fff !important;
}

input:-webkit-autofill + .morph-label{
    top: -0.5rem !important;
    font-size: 0.8rem !important;
    border-radius: 0.2rem;
    background-color: #fff !important;
}

.text-input-label{
    font-size: 0.8rem;
    color: #6c757d !important;
}

.valid{
    color: #198754 !important;
    border-color: #198754 !important;
}

.invalid{
    color: #dc3545 !important;
    border-color: #dc3545 !important;
}

select:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: #fff;
}

.select-wrapper{
    position: relative;
}

.select-wrapper i{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.file-input{
    display:inline-flex;
    justify-content: center;
    align-items: center;
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 0.75rem;
    font-size: 1rem;
    background-color: var(--theme-primary-container);
    cursor: pointer;
    border: 1px dashed #000;
    margin: 0.25rem;
    transition: ease all 0.3s;
}

.file-input:hover{
    background-color: #111;
    color: #fff;
    transform: rotate(90deg);
    border-radius: 5rem;
}