.rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: #000;
    font-weight: bold;
}

.rdrDateDisplayWrapper {
    background: var(--material-beage);
    margin: 0rem -1.5rem;
}

.rdrDateDisplayItemActive input {
    color: #000;
    font-weight: bold;
}

.rdrDateDisplayItem input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #000;
    font-size: 16px;
}

.rdrDateDisplayItem {
    background: var(--material-beage);
    box-shadow:none;
    border: none;
}


.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    margin: 0rem -1.5rem;
    padding: 0rem 0.5rem;
    padding-top: 0;
    background: var(--material-beage);
}

.rdrYearPicker {
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.rdrMonthPicker {
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.rdrMonths {
    display: flex;
}

.rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrEndEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
}

.rdrDayToday .rdrDayNumber span {
    font-weight: 500;
}

.rdrMonth {
    width: 100%;
    padding: 0;
}

.rdrCalendarWrapper {
    color: #000000;
    font-size: 14px;
    width: 100%;
}

.rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3em;
    text-align: center;
    color: #1d2429;
}

.rdrMonth .rdrWeekDays {
    padding: 10px 0;
}

.rdrDateDisplay {
    margin: 0;
}

.rdrNextPrevButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 20px;
    height: 40px;
    margin: 0 0.5em;
    padding: 0px;
    border: 0;
    border-radius: 4px;
    background: #fff;
}

.rdrNextButton i {
    margin: 0;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
}

.rdrMonthAndYearPickers select {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #000;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 12px center;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}
