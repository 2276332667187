.search-input{
    width: 100%;
    font-weight: 500;
    padding: 1rem;
    border: 1px solid var(--material-grey);
    border-radius: 0.3rem;
    transition: ease-in-out all 0.1s;
}

.search-result{
    position: absolute;
    top: calc(100% - 10px);
    left: 0px;
    background: #fff;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0.5rem;
    width: calc(100%);
    border: 1px solid var(--material-grey);
    border-top: none;
    z-index: 50;
    max-height: 300px;
    overflow: scroll;
}

.search-result .item{
    padding: 0.5rem;
    cursor:pointer;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: ease-in-out all 0.1s;
}

.search-result .header{
    padding: 0.5rem;
    padding-top: 0;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.search-result .item:hover{
    background-color: #f2f2f2;
}
