.hotel-card{
    width: calc(25% - 20px);
    margin: 10px;
    background: white;
    padding: 8px;
}

.hotel-card .text-small {
    font-size: 0.8rem;
}

.fw-500 {
    font-weight: 500;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hotel-card {
    border-radius: 10px;
    cursor: pointer;
    transition: ease-in-out all 0.2s;
}


.hotel-card img{
    object-fit: cover;
    height: 225px;
    border-radius: 12px;
    background: rgb(241, 241, 241);
    width: 100%;
}

@media (max-width: 1000px) {
    .hotel-card{
        width: calc(33.33% - 20px);
    }

}
@media (max-width: 800px) {
    .hotel-card{
        width: calc(50% - 20px);
    }
}
@media (max-width: 600px) {
    .hotel-card{
        width: calc(100%);
    }
}
