.overlay{
z-index: 9999;
left: 0;
top: 0;
right: 0;
max-width: 100%;
height: 100%;
overflow-x: hidden;
overflow-y: auto;
outline: 0;
background: rgba(0,0,0,0.5);
align-items: center;
justify-content: center;
}

.contact{
left: 0;
right: 0;
backdrop-filter: blur(10px);
background-color: rgba(255,255,255,0.8);
border-radius: 1rem;
overflow: hidden;
overflow-y: auto ;
}