.rooms-popup{
    position: absolute;
    top: calc(100% - 0.2rem);
    left: -1px;
    background-color: #fff;
    border-radius: 0px 0px 0.5rem 0.5rem;
    padding: 1rem;
    padding-top: 0px;
    width: calc(100% + 2px);
    border-top: 0px !important;
}

.inc-dec-btn{
    cursor: pointer;
    height:25px;
    min-width:25px;
    background: #000;
    color:#fff;
    border: 1px solid #000;
    transition: ease-in-out all 0.15s;
}

.inc-dec-btn:hover{
    background: #fff;
    color:#000;
}