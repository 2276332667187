.FadeIn-Effect{
    animation: fadeInAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;

    }
    100% {
        opacity: 1;
     }
}

.FadeUp-Effect{
    animation: fadeUpAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeUpAnimation {
    0% {
        opacity: 0;
        transform: translateY(10%);

    }
    100% {
        opacity: 1;
        transform: translateY(0%);
     }
}

.FadeDown-Effect{
    animation: fadeDownAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeDownAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
     }
}

.FadeLeft-Effect{
    animation: fadeLeftAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeLeftAnimation {
    0% {
        opacity: 0;
        transform: translateX(-10%);

    }
    100% {
        opacity: 1;
        transform: translateX(0%);
     }
}

.FadeRight-Effect{
    animation: fadeRightAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeRightAnimation {
    0% {
        opacity: 0;
        transform: translateX(10%);

    }
    100% {
        opacity: 1;
        transform: translateX(0%);
     }
}

.transition-ease{
    transition: ease-in-out all 0.2s
}

.hoverExpand{
    transition: ease-in-out all 0.3s;
}
.hoverExpand:hover{
    transform: scale(105%);
}

.spinner > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dark > div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #000;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}


@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.FadeOut-Effect{
    animation: fadeOutAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;

    }
    100% {
        opacity: 0;
    }
}

.FadeOutLeft-Effect{
    animation: fadeOutLeftAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeOutLeftAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);

    }
    100% {
        opacity: 0;
        transform: translateX(-10%);
    }
}

.FadeOutRight-Effect{
    animation: fadeOutRightAnimation ease 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeOutRightAnimation {
    0% {
        opacity: 1;
        transform: translateX(0%);

    }
    100% {
        opacity: 0;
        transform: translateX(10%);
    }
}