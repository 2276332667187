.tabs-menu{
    background-color: var(--material-green);
    border-radius: 0.5rem;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.tabs-menu .tab-active{
    border-radius: 0.5rem;
    margin:0.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    background-color: #fff;
    flex: 1;
    font-weight: bold;

}

.tabs-menu .tab{
    border-radius: 0.5rem;
    margin:0.5rem;
    padding: 0.5rem 0.5rem;
    text-align: center;
    cursor: pointer;
    transition: ease all 0.3s;
    min-width: 100px;

}

.tabs-menu .tab:hover{
    background: rgba(0,0,0,0.05);
}