.image-thumbnail{
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.image-thumbnail:hover .thumb{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.image-thumbnail .thumb{
    display: none;
    transition: ease all 0.15s;
    
}

.image-thumbnail .thumb.show{
    display: block;
    transition: ease all 0.15s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}


.image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #111;
    z-index: 10;
}

.image-viewer img:hover{
    transition: ease all 0.2s;
    transform: scale(98%);
}

.image-viewer .full-img{
    height: 100%;
}

.image-viewer .carousel-img{
    max-height: calc(100vh - 10rem);
}

.image-viewer .carousel-img img{
    width: 100%;
}

@media (max-width: 575.98px){
    .image-viewer .full-img{
        height: 60%;
    }
    .image-viewer .carousel-img{
        display: flex;
        align-items: flex-end;
        height: 40%;
    }
    .image-viewer .carousel-img img{
        width: 250px;
    }
}