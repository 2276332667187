.modal-content {
    border: none;
}

.modal-header{
    border: none;
    padding-left: 1.5rem;
}

.modal-footer{
    border: none;
    padding-bottom: 1.5rem;
}

.modal-footer>* {
    margin: 0;
}

.modal-body {
    display: flex;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@media (max-width: 575.98px){
    .modal-fullscreen-sm-down .modal-content {
        height: auto;
        max-height: 90%;
        border: 0;
        border-radius: 0;
        border-radius: 1rem 1rem 0px 0px;
    }
    .modal-dialog-centered {
        display: flex;
        align-items:flex-end;
        position: fixed;
        bottom: 0;
        min-height: calc(100% - 1rem);
    }
}