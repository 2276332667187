.truncate-show{
    max-height: auto;
    overflow: auto;
  }
  
  .truncate-overflow {
    --max-lines: 3;
    max-height: calc(1.2rem * var(--max-lines));
    overflow: hidden;
  }
  
  .truncate-overflow::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
  }
