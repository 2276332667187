.menu-option{
    border-radius: 0.75rem; 
    cursor: pointer;
    position: relative;
    transition: ease-in-out all 0.2s;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
}

.menu-option:hover{
    transform: scale(107%);
}

.menu-badge{
    top:10px;
    right:10px;
    font-size:0.6rem;
    position: absolute;
}